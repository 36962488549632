import { useMemo } from 'react';
import { useStorefront } from './useStorefront';

export const useLogin = () => {
  const { state, actions } = useStorefront();

  if (!state.login) throw new Error('useLogin must be used within the StorefrontContext.Provider');

  const login = useMemo(() => state.login, [state.login]);
  const toggleLoginModal = useMemo(() => actions.toggleLoginModal, [actions]);

  return { login, toggleLoginModal };
};
